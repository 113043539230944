<template>
  <div>
    <vs-prompt
      @cancel="initValues"
      @accept="submitData"
      :title="title"
      :active.sync="isDialogActiveLocal"
      accept-text="Salvar"
      cancel-text="Cancelar">

      <div>
        <div class="vx-col md:12 w-full mt-2">
          <label>Mês Referência</label>
          <v-select :options="months" v-model="dataReferenceMonth" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-validate="'required'" name="implantation-reference-month"/>
          <span class="text-danger text-sm" v-show="errors.has('implantation-reference-month')">{{ errors.first('implantation-reference-month') }}</span>
        </div>
        <div class="vx-col md:12 w-full mt-2">
          <label>Quantidade de Economias</label>
          <vs-input v-model="dataAmountOfSavings" class="mt-3 w-full"
                    v-validate="'required'" name="implantation-amount-of-savings"/>
          <span class="text-danger text-sm" v-show="errors.has('implantation-amount-of-savings')">{{ errors.first('implantation-amount-of-savings') }}</span>
        </div>
        <div class="vx-col md:12 w-full mt-2">
          <ul class="con-s">
            <li>
              <label>Escopo Obrigatório (S/N)</label>
              <vs-switch v-model="dataGoalMinimumScope" v-validate="'required'" name="implantation-goal-minimum-scope">
                <span slot="on">Sim</span>
                <span slot="off">Não</span>
              </vs-switch>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('implantation-goal-minimum-scope')">{{ errors.first('implantation-goal-minimum-scope') }}</span>
        </div>
        <div class="vx-col md:12 w-full mt-2">
          <label>DBO</label>
          <vs-input v-model="dataStateDbo" class="mt-3 w-full" name="investigation-state-dbo"/>
          <span class="text-danger text-sm" v-show="errors.has('investigation-state-dbo')">{{ errors.first('investigation-state-dbo') }}</span>
        </div>
      </div>
    </vs-prompt>

  </div>
</template>

<script>
  import vSelect from 'vue-select'

  export default {
    components: {
      'v-select': vSelect,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      isActivePromptImplantation: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    watch: {
      isActivePromptImplantation(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {

          let {

            id,
            referenceMonth,
            amountOfSavings,
            goalMinimumScope,
            stateDbo,

          } = JSON.parse(JSON.stringify(this.data))

          this.dataId = id
          this.dataReferenceMonth = referenceMonth
          this.dataAmountOfSavings = amountOfSavings
          this.dataGoalMinimumScope = goalMinimumScope
          this.dataStateDbo = stateDbo

          this.initValues()
        }
      }
    },
    data() {
      return {
        dataId: null,
        dataAmountOfSavings: "",
        dataReferenceMonth: "",
        dataGoalMinimumScope: "",
        dataStateDbo: "",

        months: [
          1,2,3,4,5,6,7,8,9,10,11,12
        ],
      }
    },
    computed: {
      isDialogActiveLocal: {
        get() {
          return this.isActivePromptImplantation
        },
        set(val) {
          if (!val) {
            this.$emit('closeDialog')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      }
    },
    methods: {
      initValues() {
        if (this.dataId) return
        this.dataId = ""
        this.dataReferenceMonth = ""
        this.dataAmountOfSavings = ""
        this.dataGoalMinimumScope = false
        this.dataStateDbo = false
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const payload = {
              implantationId: this.dataId,
              referenceMonth: this.dataReferenceMonth,
              amountOfSavings: this.dataAmountOfSavings,
              goalMinimumScope: this.dataGoalMinimumScope,
              stateDbo: this.dataStateDbo,
            }

            this.$store.dispatch("moduleContractDetails/updateImplantation", payload)
              .then(response => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Sucesso',
                  text: `Ao salvar os dados do contrato nº: ${response.data.numberContract}`
                })
              })
              .catch((error) => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Erro',
                  text: error.data.message
                })
              })
            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      },
    },

  }
</script>
<style>
  .vs-dialog {
    max-width: 450px !important;
  }
</style>
