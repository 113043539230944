<template>

  <div>
    <dialog-implantation :isActivePromptImplantation="addNewDialogImplantation"
                         @closeDialog="toggleDialogImplantation"
                         :title="titleDialogImplantation" :data="dialogImplantationData"/>

    <dialog-investigation :isActivePromptInvestigation="addNewDialogInvestigation"
                         @closeDialog="toggleDialogInvestigation"
                         :title="titleDialogInvestigation" :data="dialogInvestigationData"/>

    <dialog-fixed-remuneration :isActivePromptFixedRemuneration="addNewDialogFixedRemuneration"
                          @closeDialog="toggleDialogFixedRemuneration"
                          :title="titleDialogFixedRemuneration" :data="dialogFixedRemunerationData"/>

    <vx-card class="mb-base">

      <div class="vx-row">

        <div class="vx-col flex-1">
          <vs-list>
            <vs-list-item title="Número do Contrato" :subtitle="contract.numberContract"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Taxa Mínima de Remuneração (TMR)"
                          :subtitle="contract.tmr|formatToDecimal(4)"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Meta de Economias Encaminhadas para Tratamento"
                          :subtitle="contract.goalSavings|formatToDecimal(0)"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="30% do Total de Economias"
                          :subtitle="contract.minimumSavings|round|formatToDecimal(0)"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="110% do Total de Economias"
                          :subtitle="contract.maximumSavings|round|formatToDecimal(0)"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Valor Global" :subtitle="contract.globalValue|toCurrency"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Fonte de Recurso" :subtitle="contract.financing"></vs-list-item>
          </vs-list>

        </div>

        <div class="vx-col flex-1">
          <vs-list>
            <vs-list-item title="Remuneração Básica" :subtitle="contract.basicRemuneration|toCurrency"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Bonificação Máxima" :subtitle="contract.maximumBonus|toCurrency"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Data de Início" :subtitle="contract.startDate|formatDate"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Previsão de Término das Obras" :subtitle="contract.endDate|formatDate"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Código Bacia" :subtitle="contract.codeBowl|title"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Nome Bacia" :subtitle="contract.nameSubBowl"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Contratada" :subtitle="contract.contracted"></vs-list-item>
          </vs-list>
        </div>

        <div class="vx-col flex-1">
          <vs-list>
            <vs-list-item title="Limite Mínimo DBO" :subtitle="contract.minimumDbo|title"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Fase Atual" :subtitle="contract.currentPhase|translatePhase"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Qtde Meses Fase de Implantação"
                          :subtitle="contract.implantationPhaseMonths|title"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Qtde Meses Fase de Apuração"
                          :subtitle="contract.investigationPhaseMonths|title"></vs-list-item>
          </vs-list>
          <vs-list>
            <vs-list-item title="Qtde Meses Renda Fixa"
                          :subtitle="contract.fixedRemunerationPhaseMonths|title"></vs-list-item>
          </vs-list>

        </div>

      </div>

    </vx-card>

    <vx-card class="mb-base">
      <div class="vx-row">
        <div class="vx-col flex-2">
          <vs-list>
            <vs-list-item title="Objeto" :subtitle="contract.object"></vs-list-item>
          </vs-list>
        </div>
      </div>
    </vx-card>

    <vx-card v-if="countImplantations" class="mb-base" collapse-action title="Fase 1: Implantação">
      <vs-table stripe :data="contract.implantations" noDataText="Sem Dados">

        <template slot="thead">
          <vs-th>Atualizado em</vs-th>
          <vs-th>Mês</vs-th>
          <vs-th>DBO</vs-th>
          <vs-th>Econ. Enc. Tratamento</vs-th>
          <vs-th>Econ. Enc. Tratamento Acum.</vs-th>
          <vs-th>% Economias</vs-th>
          <vs-th>
            <vx-tooltip :text="textTooltipIpgc" position="top">IPGC Parcial</vx-tooltip>
          </vs-th>
          <vs-th>
            <vx-tooltip :text="textTooltipRemunerationMonthpay" position="top">Remun. Mensal</vx-tooltip>
          </vs-th>
          <vs-th>
            <vx-tooltip :text="textTooltipAcumulationValue" position="top">Valor Acum. Total</vx-tooltip>
          </vs-th>
          <vs-th>
            <vx-tooltip :text="textTooltipPercentage" position="top">%</vx-tooltip>
          </vs-th>
          <vs-th>
            Escopo Obrig.
          </vs-th>
          <vs-th v-if="$acl.check('manager')">Ações</vs-th>
        </template>

        <template>
          <vs-tr :key="indextr" v-for="(tr, indextr) in contract.implantations">
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.updatedAt || tr.createdAt|formatDate }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.referenceMonth }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.stateDbo }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.amountOfSavings }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.amountOfAccumulatedSavings }} </vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.percentageTotalSavings|formatToPercentage }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.ipgc|formatToDecimal(6) }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.monthlyPay|toCurrency }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.accumulationValue|toCurrency }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.percentage|formatToPercentage }}</vs-td>
            <vs-td v-bind:class="{ 'text-warning': tr.amountOfAccumulatedSavings>=contract.goalSavings }">{{ tr.goalMinimumScope? 'Sim': 'Não' }}</vs-td>
            <vs-td class="whitespace-no-wrap" v-if="$acl.check('manager')">
              <feather-icon icon="EditIcon"
                            svgClasses="w-5 h-5 hover:text-warning stroke-current"
                            class="ml-2"
                            @click.stop="mountDialogImplantation(tr)"
                            title="Atualizar Fase de Implantação"/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <vx-card v-if="countInvestigations" class="mb-base" collapse-action
             title="Fase 2: Apuração de Performance">
      <vs-table stripe :data="contract.investigations" noDataText="Sem Dados">

        <template slot="thead">
          <vs-th>Atualizado em</vs-th>
          <vs-th>Mês Referência</vs-th>
          <vs-th>DBO</vs-th>
          <vs-th>Meta DBO</vs-th>
          <vs-th>IPGC Mensal</vs-th>
          <vs-th>Remuneração Mensal</vs-th>
          <vs-th>Valor Acumulado Fase 2</vs-th>
          <vs-th>%</vs-th>
          <vs-th>Valor Acumulado Total</vs-th>
          <vs-th>%</vs-th>
          <vs-th v-if="$acl.check('manager')">Ações</vs-th>
        </template>

        <template>
          <vs-tr :key="indextr" v-for="(tr, indextr) in contract.investigations">
            <vs-td>{{ tr.updatedAt || tr.createdAt|formatDate }}</vs-td>
            <vs-td>{{ tr.referenceMonth }}</vs-td>
            <vs-td>{{ tr.stateDbo }}</vs-td>
            <vs-td>{{ tr.fmp ? 'Sim': 'Não'}}</vs-td>
            <vs-td>{{ tr.monthIpgc|formatToDecimal(4) }}</vs-td>
            <vs-td>{{ tr.monthRemuneration|toCurrency }}</vs-td>
            <vs-td>{{ tr.accumulationValue|toCurrency }}</vs-td>
            <vs-td>{{ tr.percentageOne|formatToPercentage }}</vs-td>
            <vs-td>{{ tr.accumulationValueTotal|toCurrency }}</vs-td>
            <vs-td>{{ tr.percentageTwo|formatToPercentage }}</vs-td>
            <vs-td class="whitespace-no-wrap" v-if="$acl.check('manager')">
              <feather-icon icon="EditIcon"
                            svgClasses="w-5 h-5 hover:text-primary stroke-current"
                            @click.stop="mountDialogInvestigation(tr)"
                            class="ml-2"
                            title="Atualizar Fase de Apuração"/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <vx-card v-if="countFixedRemunerations" class="mb-base" collapse-action
             :title="titleFixedRemuneration">
      <vs-table stripe :data="contract.fixedRemunerations" noDataText="Sem Dados">

        <template slot="thead">
          <vs-th>Mês</vs-th>
          <vs-th>Rem. Fixa Mensal</vs-th>
          <vs-th>Valor Acumulado</vs-th>
          <vs-th>%</vs-th>
          <vs-th>Valor Acumulado Total</vs-th>
          <vs-th>%</vs-th>
          <vs-th v-if="$acl.check('manager')">Ações</vs-th>
        </template>

        <template>
          <vs-tr :key="indextr" v-for="(tr, indextr) in contract.fixedRemunerations">
            <vs-td>{{ tr.referenceMonth }}</vs-td>
            <vs-td>{{ tr.monthRemuneration|toCurrency }}</vs-td>
            <vs-td>{{ tr.accumulationValue|toCurrency }}</vs-td>
            <vs-td>{{ tr.percentageOne|formatToPercentage }}</vs-td>
            <vs-td>{{ tr.accumulationValueTotal|toCurrency }}</vs-td>
            <vs-td>{{ tr.percentageTwo|formatToPercentage }}</vs-td>
            <vs-td class="whitespace-no-wrap" v-if="$acl.check('manager')">
              <feather-icon icon="EditIcon"
                            svgClasses="w-5 h-5 hover:text-primary stroke-current"
                            @click.stop="mountDialogFixedRemuneration(tr)"
                            class="ml-2"
                            title="Atualizar Fase de Remuneração Fixa"/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

  </div>
</template>
<script>
  import DialogImplantation from "../components/dialogs/DialogsContractDetailImplantation";
  import DialogInvestigation from "../components/dialogs/DialogsContractDetailInvestigation";
  import DialogFixedRemuneration from "../components/dialogs/DialogsContractDetailFixedRemuneration";

  export default {
    components: {
      DialogImplantation,
      DialogInvestigation,
      DialogFixedRemuneration,
    },
    data() {
      return {
        //Dialog Implantation
        addNewDialogImplantation: false,
        titleDialogImplantation: '',
        dialogImplantationData: {},

        //Dialog Investigation
        addNewDialogInvestigation: false,
        titleDialogInvestigation: '',
        dialogInvestigationData: {},

        //Dialog Fixed Remuneration
        addNewDialogFixedRemuneration: false,
        titleDialogFixedRemuneration: '',
        dialogFixedRemunerationData: {},

        contractId: this.$route.params.contractId,
        contract: {},
        // Phases of Contract
        textTooltipIpgc: "SE (Economias Enc. para Tratamento) < Limite Min. de Economia Encaminha, ENTÃO 0, SENÃO " +
          "(Economias Enc. para Tratamento) / Metas de Economias Encaminhadas  * TMR",
        textTooltipRemunerationMonthpay: "SE IPGC >= (1,1 * TMR), ENTÃO (Remuneração Básica / 42) * 1,1 * TMR, SENÃO " +
          "(Remuneração Básica /42) * IPGC",
        textTooltipAcumulationValue: "Remuneração Mensal + Valor Acumulado Mês Anterior",
        textTooltipPercentage: "Valor Acumulado / Valor Global",

        countImplantations: 0,
        countInvestigations: 0,
        countFixedRemunerations: 0,

      }
    },
    computed: {
      titleFixedRemuneration() {
        return `Fase 3: Remuneração Mensal Fixa (IPGC Médio: ${this.$options.filters.formatToDecimal(this.contract.investigations[this.contract.investigations.length-1].averageIpgc, 4)})`
      }
    },
    methods: {
      mountDialogImplantation(implantation) {
        this.titleDialogImplantation = `Atualizar Implantação`
        this.toggleDialogImplantation(true)
        this.dialogImplantationData = {
          ...implantation
        }
      },
      toggleDialogImplantation(val = false) {
        this.addNewDialogImplantation = val
      },
      mountDialogInvestigation(investigation) {
        this.titleDialogInvestigation = `Atualizar Apuração`
        this.toggleDialogInvestigation(true)
        this.dialogInvestigationData = {
          ...investigation
        }
      },
      mountDialogFixedRemuneration(fixedRemuneration) {
        this.titleDialogFixedRemuneration = `Atualizar Remuneração Fixa`
        this.toggleDialogFixedRemuneration(true)
        this.dialogFixedRemunerationData = {
          ...fixedRemuneration
        }
      },
      toggleDialogInvestigation(val = false) {
        this.addNewDialogInvestigation = val
      },
      toggleDialogFixedRemuneration(val = false) {
        this.addNewDialogFixedRemuneration = val
      },
    },
    created() {
      this.$store.dispatch("moduleContractDetails/fetchContract", this.contractId)
        .then(response => {
          this.contract = response.data
          this.countImplantations = this.contract.implantations.length
          this.countInvestigations = this.contract.investigations.length
          this.countFixedRemunerations = this.contract.fixedRemunerations.length
        })
    }
  }
</script>
