<template>
  <div>
    <vs-prompt
      @cancel="initValues"
      @accept="submitData"
      :title="title"
      :active.sync="isDialogActiveLocal"
      accept-text="Salvar"
      cancel-text="Cancelar">

      <div>
        <div class="vx-col md:12 w-full mt-2">
          <label>Mês Referência</label>
          <v-select :options="months" v-model="dataReferenceMonth" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-validate="'required'" name="fixed-remuneration-reference-month"/>
          <span class="text-danger text-sm" v-show="errors.has('fixed-remuneration-reference-month')">{{ errors.first('fixed-remuneration-reference-month') }}</span>
        </div>
      </div>
    </vs-prompt>

  </div>
</template>

<script>
  import vSelect from 'vue-select'

  export default {
    components: {
      'v-select': vSelect,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      isActivePromptFixedRemuneration: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    watch: {
      isActivePromptFixedRemuneration(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {

          let {

            id,
            referenceMonth,

          } = JSON.parse(JSON.stringify(this.data))

          this.dataId = id
          this.dataReferenceMonth = referenceMonth

          this.initValues()
        }
      }
    },
    data() {
      return {
        dataId: null,
        dataReferenceMonth: "",

        months: [
          1,2,3,4,5,6,7,8,9,10,11,12
        ],
      }
    },
    computed: {
      isDialogActiveLocal: {
        get() {
          return this.isActivePromptFixedRemuneration
        },
        set(val) {
          if (!val) {
            this.$emit('closeDialog')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      }
    },
    methods: {
      initValues() {
        if (this.dataId) return
        this.dataId = ""
        this.dataReferenceMonth = ""
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const payload = {
              fixedRemunerationId: this.dataId,
              referenceMonth: this.dataReferenceMonth,
            }

            this.$store.dispatch("moduleContractDetails/updateFixedRemuneration", payload)
              .then(response => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Sucesso',
                  text: `Ao salvar os dados do contrato nº: ${response.data.numberContract}`
                })
              })
              .catch(() => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Erro',
                  text: 'Ao atualizar.'
                })
              })
            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      },
    },

  }
</script>
<style>
  .vs-dialog {
    max-width: 450px !important;
  }
</style>
